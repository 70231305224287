import React from 'react';
import logoheader from '../../../assets/logo/logoinnews.png';

const HeaderPricing = () => {
  return (
<div className="header-news-wraper flex flex-col items-center justify-center text-center">
<div className="header-news-title-wraper">
  <div className="header-news-title-large-wraper mt-4">
    <h1 className="header-news-title-large flex items-center justify-center">
      <span className="logo-wrapper mr-2">
        <img src={logoheader} alt="Logo" className="logo-img h-auto max-w-xs md:max-w-sm" />
      </span>
      <span className="header-text font-montserrat text-white text-2xl md:text-3xl lg:text-5xl font-bold">
        Pricing
      </span>
    </h1>
  </div>
  <p className="header-news-paragraph text-white text-sm md:text-base mt-2">
    Solusi cerdas untuk berbagai kebutuhan Koperasi Anda!
  </p>
</div>
</div>
  );
};

export default HeaderPricing;
