import React, { useState } from 'react';
import Logo from '../../../assets/svg/logonavbar.svg';
import IconPack from '../../../pages/widget/home/IconPack'; 
import IconPackCrossed from '../../../pages/widget/home/IconPackcrossed'; 

const WhyElkopra = () => {
  const [showIcons, setShowIcons] = useState(false);

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  return (
    <div className="text-center">
      <h1 className="font-montserrat text-2xl mt-10">Kenapa harus Elkopra?</h1>
      <div className="container mx-auto sm:px-2 lg:px-2">
        <label htmlFor="toggleB" className="flex items-center justify-center mt-6">
          <div className="relative">
            <input
              type="checkbox"
              id="toggleB"
              className="sr-only"
              onChange={toggleIcons}
              checked={showIcons}
            />
            <div className="block strip w-10 h-6 rounded-full cursor-pointer"></div>
            <div
              className={`dot absolute left-1 top-1 bg-white w-4 h-4 rounded-full transition cursor-pointer ${showIcons ? 'translate-x-full' : 'translate-x-0'
                }`}
            ></div>
          </div>
          <h2 className="ml-6 font-inter">bersama</h2>
          <img src={Logo} alt="Logo" className="ml-3 w-24" />
        </label>
        <div className="mt-6 justify-center">
          {showIcons ? <IconPackCrossed /> : <IconPack />}
        </div>
      </div>
    </div>
  );
};

export default WhyElkopra;

{/* <div className="flex justify-center mt-10 mb-10">
      <img src={iconeset} alt='' className='text-center' />
    </div> */}

{/* <div className="flex justify-center mb-10">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-4 container mx-auto">
        <div className="bg-white rounded-lg p-4 mb-4 md:mb-0">
            
        </div>
        <div className="bg-slate-600 rounded-lg p-4">
          <div className='flex'>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Simpan Pinjam</span>
            </div>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Pos Cafe</span>
            </div>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Dashboard Analitik Manager</span>
            </div>
            <div className="bg-white rounded-full inline-block max-w-max p-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Pengaturan Budget</span>
            </div>
          </div>
          <div className='flex mt-3'>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Simpanan Sukarela</span>
            </div>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Integrasi dengan Aplikasi Accounting (ERP NEXT)</span>
            </div>
          </div>
          <div className='flex mt-3'>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Rekap Transaksi</span>
            </div>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Management Anggota dan Bank</span>
            </div>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Pembayaran Tagihan</span>
            </div>
          </div>
          <div className='flex mt-3'>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Management Vendor</span>
            </div>
            <div className="bg-white rounded-full inline-block max-w-max p-2 mr-2">
                <span className="font-inter text-sm font-semibold text-blue-700">Integrasi dengan Payment Gateway</span>
            </div>
          </div>
        </div>
    </div>
</div> */
}