import React, { useState } from 'react';
import arrowright from '../assets/svg/arrowright.svg'
const Pagination = ({ totalPages, currentPage, onPageChange }) => {
  const handlePrevious = () => {
    if (currentPage > 1) onPageChange(currentPage - 1);
  };

  const handleNext = () => {
    if (currentPage < totalPages) onPageChange(currentPage + 1);
  };
  return (
    <div className="flex items-center space-x-2">
      <button
        onClick={handlePrevious}
        disabled={currentPage === 1}
        className={`px-4 py-3 cursor-pointer rounded-full border-none bg-transparent ${
          currentPage === 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black'
        }`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="size-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
        </svg>
      </button>
      {[...Array(totalPages)].map((_, index) => {
        const pageNumber = index + 1;
        return (
          <button
          key={pageNumber}
          onClick={() => onPageChange(pageNumber)}
          className={`w-12 h-12 flex items-center justify-center cursor-pointer rounded-full border-none ${
            currentPage === pageNumber
              ? 'bg-[#004AAD] text-white'
              : 'bg-white text-[#A1A1A1]'
          }`}
        >
          {pageNumber}
        </button>
        );
      })}
      <button
        onClick={handleNext}
        disabled={currentPage === totalPages}
        className={`px-4 py-3 cursor-pointer rounded-full border-none bg-transparent ${
          currentPage === totalPages ? 'text-gray-400 cursor-not-allowed' : 'text-black'
        }`}
      >
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className=" size-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
        </svg>
      </button>
    </div>
  );
};

export default Pagination;
