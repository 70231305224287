import { memo } from "react";
import { Outlet } from "react-router-dom";
import { Footer, Navbar } from ".";
export default memo(() => {
  return (
    <>
    <Navbar/>
    <Outlet/>
    <Footer/>
    </>
  );
});