import React, { createContext, memo, useContext, useEffect, useState } from 'react'
import News from '../../pages/news'
import axios from 'axios'
import { env } from '../../config/EnvironmentConfig'
const controller = createContext(null)
export const useControllerState = () => {
    return useContext(controller)
}
const Controller = memo(() => {
   
    const [berita, setBerita] = useState(null)
    const [loading, setLoading] = useState(true);
    const [beritaTerkini, setBeritaTerkini] = useState(null)
    const [loadingTerkini, setLoadingTerkini] = useState(true);


    const getData = async () => {
      try {
        const response = await axios.get(env.API + "/core/landing-page/news?categories=popular");
        if (response.status === 200) {
            setTimeout(() => {
                setBerita(response.data);
                setLoading(false); 
              }, 1000);
        } else {
          console.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    };
    const getDataTerkini = async () => {
      try {
        const response = await axios.get(env.API + "/core/landing-page/news?categories=terkini");
        if (response.status === 200) {
            setTimeout(() => {
                setBeritaTerkini(response.data);
                setLoadingTerkini(false); 
              }, 1000);
        } else {
          console.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    };
    useEffect(() => {
        getData();
        getDataTerkini();
      }, []);

  return  (
   <controller.Provider
   value={
    {
     berita,
     loading,
     beritaTerkini,
     loadingTerkini,
    }
   }
   >
    <News/>
   </controller.Provider>
  )
})

export default Controller