import React, { createContext, useContext } from 'react';
import ReactDOM from 'react-dom/client';
import router from './router';
import Layout from './global/Layout';
import './index.css'
import './custom/Button.css'
import './custom/HeaderHome.css'
import './custom/HeaderFeature.css'
import './custom/Tabs.css'
import './custom/HeaderOurServices.css'
import './custom/HeaderNews.css'
import './custom/HeaderPricing.css'
import './custom/HeaderFeaturePricing.css'
import './custom/HeaderUjiCoba.css'
import '@fontsource/montserrat'
import '@fontsource/inter'
import DetailNews from './pages/components/Detail/DetailNews';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
const root = ReactDOM.createRoot(document.getElementById('root'));
const MainContext = createContext(null);
  
  export const useMainControllerState = () => {
    return useContext(MainContext);
  };

  const routes = router.map(el => {
    const Component = require(`./controller/${el.name}`).default
    return {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: el.name,
                element: <Component />,
            },
            {
              path:"detailnews/:id",
              element:<DetailNews/>
            },
        ],
    }
  })

root.render(
    <MainContext.Provider value={{}}>
     <RouterProvider router={
            createBrowserRouter(routes)
        } />
    </MainContext.Provider>
);
