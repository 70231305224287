import React, { useState } from 'react'
import pengajuanPinjaman from '../../../assets/svg/layanan/pengajuanpinjaman.svg'
import angsuran from '../../../assets/svg/layanan/angsuran.svg'
import onlineApproval from '../../../assets/svg/layanan/onlineapproval.svg'
import pengaturanBudget from '../../../assets/svg/layanan/pengaturanbudget.svg'
import pelunasanPinjaman from '../../../assets/svg/layanan/pelunasanpinjaman.svg'
import downloadRekapData from '../../../assets/svg/layanan/downloadrekapdata.svg'
import rekapTransaksi from '../../../assets/svg/layanan/rekaptransaksi.svg'
import informasiPoint from '../../../assets/svg/layanan/informasipointtransaksi.svg'
import daftarSimpanan from '../../../assets/svg/layanan/daftarsimpanan.svg'
import deliveryOrder from '../../../assets/svg/layanan/deliveryorder.svg'
import managementVoucher from '../../../assets/svg/layanan/managementvoucher.svg'
import managementVendor from '../../../assets/svg/layanan/managementvendor.svg'
import multiTenant from '../../../assets/svg/layanan/multitenant.svg'
import pencairanDana from '../../../assets/svg/layanan/pencairandana.svg'
import pemabayarnQR from '../../../assets/svg/layanan/pembayaranqr.svg'
import akuntansi from '../../../assets/svg/layanan/akuntansi.svg'
import masterData from '../../../assets/svg/layanan/masterdata.svg'
import laporanKeuangan from '../../../assets/svg/layanan/laporankeuangan.svg'
import daftarProyek from '../../../assets/svg/layanan/daftarproyek.svg'
import transaksiInvestasi from '../../../assets/svg/layanan/transaksiinvestasi.svg'
import dashboard from '../../../assets/svg/layanan/dashboard.svg'
import tambahProyek from '../../../assets/svg/layanan/tambahproyek.svg'
import pengembalianDana from '../../../assets/svg/layanan/laporankeuangan.svg'
import laporanProyek from '../../../assets/svg/layanan/laporanproyek.svg'
import verifikasiAnggota from '../../../assets/svg/layanan/verifikasianggota.svg'
import pulsaDanPaketData from '../../../assets/svg/layanan/pulsadanpaketdata.svg'
import pln from '../../../assets/svg/layanan/pln.svg'
import bpjs from '../../../assets/svg/layanan/bpjs.svg'
import pdam from '../../../assets/svg/layanan/pdam.svg'
import internetDanTvKabel from '../../../assets/svg/layanan/internetdantvkabel.svg'
import pbb from '../../../assets/svg/layanan/pbb.svg'
import streaming from '../../../assets/svg/layanan/netflix.svg'
import tiketBus from '../../../assets/svg/layanan/tiketbus.svg'
const Layanan = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };
  return (
    <>
    <div className='bg-white pb-5 pt-5'>
    <div className="container mx-auto mb-5">
        <h1 className="font-montserrat font-semibold text-2xl">
            Layanan Terbaik untuk Kebutuhan Operasional <br/> 
            <span className="block mt-2">Koperasi yang Efektif dan Efesien.</span>
        </h1>
    </div>
    <div className='container mx-auto'>
        <div className="tabs-container-wraper">
            <div 
                className={`tab ${activeTab === 1 ? 'active' : ''}`}
                onClick={() => handleTabClick(1)}
            >
            <h5 className='font-montserrat font-semibold text-lg text-gray-900'>Simpan Pinjam</h5>
        </div>
        <div
            className={`tab ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => handleTabClick(2)}
        >
            <h5 className='font-montserrat font-semibold text-lg text-gray-900 ml-5'>Informasi SPSW</h5>
        </div>
        <div
            className={`tab ${activeTab === 3 ? 'active' : ''}`}
            onClick={() => handleTabClick(3)}
        >
            <h5 className='font-montserrat font-semibold text-lg text-gray-900 ml-5'>POS Cafe</h5>
        </div>
        <div
            className={`tab ${activeTab === 4 ? 'active' : ''}`}
            onClick={() => handleTabClick(4)}
        >
            <h5 className='font-montserrat font-semibold text-lg text-gray-900 ml-5'>Akuntansi</h5>
        </div>
        <div
            className={`tab ${activeTab === 5 ? 'active' : ''}`}
            onClick={() => handleTabClick(5)}
        >
            <h5 className='font-montserrat font-semibold text-lg text-gray-900 ml-5'>Urun Dana</h5>
        </div>
        <div
            className={`tab ${activeTab === 6 ? 'active' : ''}`}
            onClick={() => handleTabClick(6)}
        >
            <h5 className='font-montserrat font-semibold text-lg text-gray-900 ml-5'>PPOB</h5>
        </div>
    </div>
    {activeTab === 1 && (   
    <>
    <div className='mt-5 mb-5'>
     <div className='bg-[#F8F8F8] rounded-lg'>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">User</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={pengajuanPinjaman} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Pengajuan Pinjaman</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Laporan penerimaan pengajuan pinjaman dari anggota koperasi</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={angsuran} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Angsuran</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Laporan pembayaran angsuran anggota koperasi secara online.</p>
                    </div>
                </div>
            </div>
        </div>
            {/* <hr class="border-t border-gray-300 opacity-60 mt-4 w-4/5 mx-auto"/> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">Manager</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={onlineApproval} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Online Approval</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Lakukan persetujuan pinjaman secara online dalam satu aplikasi.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={pengaturanBudget} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Pengaturan Budget</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Lakukan alokasi budget koperasi untuk setiap jenis pinjaman.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={pelunasanPinjaman} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Pelunasan Pinjaman</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Laporan pelunasan angsuran anggota terdata secara update.</p>
                    </div>
                </div>
            </div>
        </div>
        {/* <hr class="border-t border-gray-300 opacity-60 mt-4 w-4/5 mx-auto"/> */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">Admin</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={downloadRekapData} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Download Rekap Data</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Ringkasan atau laporan yang berisi daftar semua transaksi yang < br/> telah dilakukan atau diterima koperasi dalam periode tertentu.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={rekapTransaksi} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Rekap Transaksi</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Ringkasan atau laporan yang berisi daftar semua transaksi yang < br/> telah dilakukan atau diterima koperasi dalam periode tertentu.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={pengajuanPinjaman} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Pengajuan Pinjaman</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Laporan penerimaan pengajuan pinjaman dari anggota koperasi</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </>
    )}
     {activeTab === 2 && (   
    <>
       <div className='mt-5 mb-5'>
     <div className='bg-[#F8F8F8] rounded-lg'>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">User</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={informasiPoint} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Informasi Point Transaksi</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Poin yang didapatkan oleh anggota atas transaksi yang telah <br/>dilakukan sesuai ketentuan koperasi.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={daftarSimpanan} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Daftar Simpanan</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Pantau simpanan secara online melalui satu aplikasi mobile.</p>
                    </div>
                </div>
            </div>
        </div>
            {/* <hr class="border-t border-gray-300 opacity-60 mt-4 w-4/5 mx-auto"/> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">Admin</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={downloadRekapData} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Download Rekap Data</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Unduh riwayat data transaksi simpanan anggota yang berlangsung <br/> selama periode tertentu.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </>
    )}
     {activeTab === 3 && (   
    <>
        <div className='mt-5 mb-5'>
     <div className='bg-[#F8F8F8] rounded-lg'>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">User</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={pemabayarnQR} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Pembayaran by QR Code</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan pilihan pembayaran secara digital menggunakan <br/>scan QR Code.</p>
                    </div>
                </div>
            </div>
        </div>
            {/* <hr class="border-t border-gray-300 opacity-60 mt-4 w-4/5 mx-auto"/> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">Admin</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={downloadRekapData} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Download Rekap Data</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Unduh riwayat data transaksi sub unit usaha koperasi yang berlangsung <br/>selama periode tertentu.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={deliveryOrder} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Delivery Order</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Pantau status dan atur proses pengiriman berdasarkan batch pengiriman.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={managementVoucher} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Management Voucher</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Membuat dan mengelola voucher diskon untuk anggota koperasi dengan <br/>syarat dan ketentuan berlaku.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={managementVendor} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Management Vendor</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Mengelola dan pemantauan terhadap permintaan barang vendor.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={multiTenant} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Multi Tenant</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Mengelola beberapa data vendor dalam satu admin.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={rekapTransaksi} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Rekap Transaksi</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Ringkasan yang berisi daftar semua transaksi sub unit yang telah <br/>dilakukan atau diterima koperasi dalam periode tertentu.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={pencairanDana} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Pencairan Dana</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Pantau status riwayat pencairan dengan satu aplikasi.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </>
    )}
    {activeTab === 4 && (   
    <>
         <div className='mt-5 mb-5'>
     <div className='bg-[#F8F8F8] rounded-lg'>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">Akuntansi</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={laporanKeuangan} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Laporan Keuangan</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Catatan informasi keuangan yang berkaitan dengan seluruh transaksi yang <br/>terjadi di Koperasi dalam periode tertentu.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={masterData} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Master Data</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Informasi data yang berkaitan dengan operasional koperasi <br/>yang tersimpan secara digital.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={akuntansi} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Akuntansi</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan fitur yang berintegrasi dengan platform akuntansi lain (Zahir, Accurate).</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </>
    )}
    {activeTab === 5 && (   
    <>
       <div className='mt-5 mb-5'>
     <div className='bg-[#F8F8F8] rounded-lg'>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">User</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={daftarProyek} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Daftar Proyek</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Informasi data yang berkaitan dengan operasional koperasi yang <br/>tersimpan secara digital.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={transaksiInvestasi} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Transaksi Investasi</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Unduh riwayat data transaksi sub unit usaha koperasi yang berlangsung <br/>selama periode tertentu.</p>
                    </div>
                </div>
            </div>
        </div>
            {/* <hr class="border-t border-gray-300 opacity-60 mt-4 w-4/5 mx-auto"/> */}
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">Admin</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={dashboard} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Dashboard</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Lakukan pemantauan terhadap transaksi urun dana yang berlangsung <br/>selama periode tertentu.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={tambahProyek} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Tambah Proyek</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Tambah proyek dengan menambahkan informasi detail proyek.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={pengembalianDana} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Pengembalian Dana</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Pengembalian dari sejumlah dana dan keuntungan yang telah terkumpul <br/>sesuai dengan jatuh tempo urun dana.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={laporanProyek} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Laporan Proyek</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Membuat dan memantau laporan proyek urun dana yang sudah dibuat <br/>dan dikirim ke seluruh investor.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={verifikasiAnggota} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Verifikasi Anggota</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Membantu mengkonfirmasi kelengkapan anggota terhadap syarat <br/>dan ketentuan anggota urun dana.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </>
    )}
     {activeTab === 6 && (   
    <>
       <div className='mt-5 mb-5'>
     <div className='bg-[#F8F8F8] rounded-lg'>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat text-gray-900">Layanan</h2>
                </div>
            </div>
            <div className="mb-4">
                <div className="flex mt-5">
                    <img src={pulsaDanPaketData} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Pulsa Dan Paket Data</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan layanan pembelian dan pembayaran produk pulsa dan <br/>paket data provider.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={pln} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>PLN</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan pembayaran tagihan listrik PLN secara online.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={bpjs} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>BPJS</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan pembayaran tagihan BPJS secara online.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={pdam} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>PDAM</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan pembayaran tagihan PDAM secara online.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={internetDanTvKabel} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Internet dan Tv Kabel</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan pembayaran tagihan Internet dan Tv kabel secara online.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={pbb} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>PBB</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan pembayaran tagihan PBB secara online.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={streaming} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Streaming</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan layanan pembelian dan pembayaran tagigahn streaming secara online.</p>
                    </div>
                </div>
                <div className="flex mt-5">
                    <img src={tiketBus} alt='' className='' />
                    <div className='ml-4'>
                        <h1 className='font-inter font-semibold text-sm text-gray-900'>Tiket Bus</h1>
                        <p className='font-inter text-sm text-gray-400 mt-1'>Menyediakan layanan pembelian tiket bus secara online.</p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
    </>
    )}
</div>
</div>
    </>
  )
}

export default Layanan
