import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import imagelong from '../../../assets/png/imagelongcard2.png';
import { useControllerState } from '../../../controller/news';
import HeaderNews from './HeaderNews';

const ArtikelPopuler = () => {
  const { berita, loading, beritaTerkini, loadingTerkini } = useControllerState();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredBerita, setFilteredBerita] = useState([]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSearchSubmit = (e) => {
    if (e.key === 'Enter') {
      const allBerita = [...berita?.data, ...beritaTerkini?.data];
      const results = allBerita.filter((item) =>
        item.judul.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredBerita(results);
      setSearchQuery('');
    }
  };

  return (
    <>
     <HeaderNews
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
        handleSearchSubmit={handleSearchSubmit}
      />
      <div className="container mx-auto flex items-center mt-10">
        <div className="bg-blue-900 p-1 h-10"></div>
        <p className="text-left font-montserrat text-2xl font-semibold px-4">Artikel Populer</p>
        <Link to="/blogpage" className="text-left font-inter text-base px-4 ml-auto text-blue-900 font-normal cursor-pointer no-underline">
          Lihat Semua
        </Link>
      </div>
      <div className='container mx-auto mb-5 mt-5'>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {(loading ? [...Array(3)] : filteredBerita.length > 0 ? filteredBerita : berita?.data)?.map((item, index) => (
            <div key={item?.id || index} className="bg-white rounded-lg">
              <div className='flex justify-center'>
                {loading ? (
                  <div className='w-full h-52 bg-gray-300 rounded-t-lg animate-pulse'></div>
                ) : (
                  <img
                    src={item.thumbnail}
                    alt={item.judul}
                    className='w-full h-52 rounded-t-lg object-cover'
                  />
                )}
              </div>
              <div className='px-5 mt-5 mb-5'>
                {loading ? (
                  <div className='w-32 h-4 bg-gray-300 rounded mb-2 animate-pulse'></div>
                ) : (
                  <h1 className='font-inter text-base text-blue-600 font-normal mb-2'>
                    {item.categories
                      ? item.categories.charAt(0).toUpperCase() + item.categories.slice(1).toLowerCase()
                      : 'Kategori Tidak Tersedia'}
                  </h1>
                )}
                {loading ? (
                  <div className='w-48 h-6 bg-gray-300 rounded animate-pulse'></div>
                ) : (
                  <Link
                    to={`/detailnews/${item.id}`}
                    className="text-lg font-semibold text-gray-800 hover:underline font-montserrat no-underline"
                  >
                    {item.judul}   
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Terkini Section */}
      <div className="container mx-auto flex items-center mt-20">
        <div className="bg-blue-900 p-1 h-10"></div>
        <p className="text-left font-montserrat text-2xl font-semibold px-4">Terkini</p>
        <Link to="/blogpage" className="text-left font-inter text-base px-4 ml-auto text-blue-900 font-normal cursor-pointer no-underline">
          Lihat Semua
        </Link>
      </div>

      <div className='container mx-auto mb-5 mt-5'>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
          <div className="mb-4">
            <div className="bg-transparent rounded-lg relative bg-blend-lighten md:bg-blend-darken">
              {loadingTerkini ? (
                <div className='w-full h-60 bg-gray-300 rounded-lg animate-pulse'></div>
              ) : (
                <img src={beritaTerkini?.data[0]?.thumbnail || imagelong} alt='' className='w-full rounded-lg' style={{height:'100vh'}} />
              )}
              <div className="absolute top-0 left-0 w-full h-full bg-black opacity-50 rounded-lg"></div>
              <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center px-5 mt-36">
                {loadingTerkini ? (
                  <div className='w-32 h-4 bg-gray-300 rounded mb-2 animate-pulse'></div>
                ) : (
                  <p className='text-xs font-inter text-blue-900 mt-20 font-normal'>
                    {beritaTerkini?.data[0]?.categories || 'Kategori Tidak Tersedia'}
                  </p>
                )}
                {loadingTerkini ? (
                  <div className='w-48 h-6 bg-gray-300 rounded animate-pulse'></div>
                ) : (
                  <h2 className="text-3xl font-semibold mb-2 font-montserrat text-white">
                    {beritaTerkini?.data[0]?.judul || 'Judul Tidak Tersedia'}
                  </h2>
                )}
              </div>
            </div>
          </div>

          <div className="mb-4">
            {loadingTerkini ? (
              [...Array(4)].map((_, idx) => (
                <div key={idx} className="bg-transparent rounded-lg mt-2">
                  <div className='w-full h-44 bg-gray-300 animate-pulse rounded-2xl'></div>
                </div>
              ))
            ) : (
              <>
                {beritaTerkini?.data.slice(1, 5).map((item, index) => (
                  <div key={item.id} className="bg-transparent rounded-lg mt-2">
                    <img src={item.thumbnail} alt='' className='w-full h-44 rounded-2xl' />
                  </div>
                ))}
              </>
            )}
          </div>

          <div className="mb-4">
            {loadingTerkini ? (
              [...Array(4)].map((_, idx) => (
                <div key={idx} className="bg-transparent p-8 rounded-lg mt-2">
                  <div className='w-full h-6 bg-gray-300 animate-pulse rounded mb-2'></div>
                </div>
              ))
            ) : (
              beritaTerkini?.data.slice(1, 5).map((item, index) => (
                <div key={item.id} className="bg-transparent rounded-lg p-14">
                  <p className='text-xs font-inter text-blue-600 font-normal no-underline'>
                    {item.categories  ? item.categories.charAt(0).toUpperCase() + item.categories.slice(1).toLowerCase()
                      : 'Kategori Tidak Tersedia'}
                  </p>
                  <Link
                    to={`/detailnews/${item.id}`}
                    className="text-sm font-semibold text-gray-800 hover:underline font-montserrat no-underline"
                  >
                    {item.judul}   
                  </Link>
                </div>
              ))
            )}
          </div>
        </div>
      </div>

      <div className='flex flex-col items-center justify-center'>
        <Link to='/blogpage' className='font-montserrat text-sm text-blue-900 font-semibold cursor-pointer no-underline'>
          BACA LEBIH BANYAK
        </Link>
      </div>
    </>
  );
}

export default ArtikelPopuler;
