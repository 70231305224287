import React, { createContext, memo, useContext, useEffect, useState } from 'react'
import DetailNews from '../../pages/Detail/detailnews'
const controller = createContext(null)
export const useControllerState = () => {
    return useContext(controller)
}
const Controller = memo(() => {
   
    // const [berita, setBerita] = useState([])
    // const [loading, setLoading] = useState(true);

    // useEffect(() => {
    //     const getData = async () => {
    //       try {
    //         const response = await axios.get('https://newsapi.org/v2/everything?q=bitcoin&apiKey=1aacec38385e4ffd883ee8b21b459b7e');
    //         if (response.status === 200) {
    //             setTimeout(() => {
    //                 setBerita(response.data.articles);
    //                 setLoading(false); 
    //               }, 1000);
    //         } else {
    //           console.error(`Error: ${response.status}`);
    //         }
    //       } catch (error) {
    //         console.error('Error:', error.message);
    //       }
    //     };
    
    //     getData();
    //   }, []);

  return  (
   <controller.Provider
   value={
    {
    //  berita,
    //  loading
    }
   }
   >
    <DetailNews/>
   </controller.Provider>
  )
})

export default Controller