import React from 'react'
import HeaderNews from '../widget/newsandblog/HeaderNews'
import ArtikelPopuler from '../widget/newsandblog/ArtikelPopuler'

const News = () => {
  return (
    <>
     {/* <HeaderNews/> */}
     <ArtikelPopuler/>
    </>
  )
}

export default News
