import React from 'react'
import logoheader from '../../../../assets/logo/logoinnews.png'
const HeaderUjiCoba = () => {
  return (
   <>
    <div className='header-our-wraper'>
  <div className='header-our-title-wraper text-center'>
    <div className='header-our-title-large-wraper'>
      <h1 className="header-our-title-large">
        Uji Coba Gratis
      </h1>
      <div className='flex justify-center mt-2'>
        <img src={logoheader} alt="" className="" />
      </div>
    </div>
  </div>
</div>
   </>
  )
}

export default HeaderUjiCoba
