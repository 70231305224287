import React from 'react'
import HeaderBlogPage from '../../widget/Detail/blogpage/HeaderBlogPage'

const BlogPage = () => {
  return (
    <>
   <HeaderBlogPage/>
    </>
  )
}

export default BlogPage
