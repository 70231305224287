import React, { useState } from "react";
import arrowup from '../../../assets/svg/arrowup.svg'
import arrowdown from '../../../assets/svg/arrowdown.svg'
const Accordion = ({ items }) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <div>
     {items.map((item, index) => (
  <div key={index} className="mb-4">
    <button
      className="w-full bg-white py-5 px-4 text-left text-blue-900 cursor-pointer border-none rounded-lg font-inter text-base font-semibold"
      onClick={() => handleClick(index)}
      style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
    >
    {item.title}
    <img
        src={activeIndex === index ? arrowup : arrowdown}
        alt={activeIndex === index ? "Up Arrow" : "Down Arrow"}
        style={{marginLeft:'auto'}}
      />
    </button>
    <div
      className={`transition-all duration-300 ${
        activeIndex === index ? 'block' : 'hidden'
      }`}
      style={{ maxHeight: activeIndex === index ? '' : '0' }}
    >
      <div className="bg-white p-4"><p className=" font-inter text-gray-400 text-base">{item.content}</p></div>
    </div>
  </div>
))}
    </div>
  );
};

export default Accordion;
