import React from 'react';
import kominfos from '../../../assets/png/partisipasi/kominfoio.png';
import hatch from '../../../assets/png/partisipasi/hatch.png';
import inabuyer from '../../../assets/png/partisipasi/inabuyer.png';
import indigo from '../../../assets/png/partisipasi/indigo.png';
import pdc from '../../../assets/png/partisipasi/pdc.png';
import idtex from '../../../assets/png/partisipasi/idtex.png';
import dti from '../../../assets/png/partisipasi/dti.png';
import swc from '../../../assets/png/partisipasi/swc.png';
import aibp from '../../../assets/png/partisipasi/aibp.png';
import nexthub from '../../../assets/png/partisipasi/nexthub.png';

const images = [
    kominfos, hatch, inabuyer, indigo,
    pdc, idtex, dti, swc,
    aibp, nexthub
];

const Partisipasi = () => {
    return (
        <div className="container mx-auto px-4 sm:px-2 lg:px-2">
            <div className="pt-20">
                <h1 className="font-montserrat text-2xl text-center pb-4">Partisipasi Kami</h1>
                <div className="bg-white rounded-lg p-4 lg:p-8">
                    <div className="grid grid-cols-2 sm:grid-cols-4 gap-y-1">
                        {images.slice(0, 8).map((image, index) => (
                            <div
                                key={index}
                                className="flex justify-center items-center px-1"
                            >
                                <img
                                    src={image}
                                    alt={`Partisipasi ${index}`}
                                    className="w-40 h-24 object-contain"
                                />
                            </div>
                        ))}
                    </div>
                    <div className="grid grid-cols-2 justify-center mt-4 gap-y-1">
                        {images.slice(8).map((image, index) => (
                            <div
                                key={index}
                                className="flex justify-center items-center px-1"
                            >
                                <img
                                    src={image}
                                    alt={`Partisipasi ${index + 8}`}
                                    className="w-40 h-24 object-contain"
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Partisipasi;
