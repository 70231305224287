import React from 'react';
import logoheader from '../../../assets/svg/logowhite.svg';

const HeaderOurServices = () => {
  return (
    <>
    <div className='header-our-wraper'>
      <div className='header-our-title-wraper'>
        <div className='header-our-title-large-wraper'>
            <h1 className='header-our-title-large'>
              <span className='header-our-title-text'>
                Kemudahan Mengelola Koperasi Hanya dalam Satu Genggaman
              </span>
            </h1>
        </div>
      </div>
   </div>
    </>
  )
}

export default HeaderOurServices;
