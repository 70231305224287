import React from 'react';
import logoheader from '../../../assets/svg/logoinnews.svg';

const HeaderNews = ({ searchQuery, handleSearchChange, handleSearchSubmit }) => {
  return (
    <div className="header-news-wraper flex flex-col items-center justify-center text-center">
    <div className="header-news-title-wraper">
      <div className="header-news-title-large-wraper mt-4">
        <h1 className="header-news-title-large flex items-center justify-center">
          <span className="logo-wrapper mr-2">
            <img src={logoheader} alt="Logo" className="logo-img h-auto max-w-xs md:max-w-sm" />
          </span>
          <span className="header-text font-montserrat text-white text-2xl md:text-3xl lg:text-5xl font-bold">
            Koperasi Digital Masa Depan
          </span>
        </h1>
      </div>
      <p className="header-news-paragraph text-white text-sm md:text-base mt-2">
        Solusi cerdas untuk berbagai kebutuhan Koperasi Anda!
      </p>
      <div className="search-news-bar-wraper mt-4 w-full max-w-md mx-auto">
        <div className="relative">
          <input
            type="search"
            id="default-search"
            className="block w-full p-4 pl-10 text-sm text-white border border-gray-300 rounded-lg bg-transparent bg-opacity-70 focus:ring-blue-500 focus:outline-none"
            placeholder="Cari..."
            value={searchQuery}
            onChange={handleSearchChange}
            onKeyDown={handleSearchSubmit}
          />
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <svg className="w-5 h-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
              <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </div>
  );
};

export default HeaderNews;
