import React from "react";


const Stats = () => {
    return(
<div className='header-home-wraper'>
    <div className="text-center pt-20">
    <h1 className="font-montserrat text-2xl text-white">Dedikasi Tiada Henti, Inovasi Tiada Batas</h1>
                <div className="relative">
                    <div className="flex items-center justify-center px-1 mx-auto">
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 mb-8">
                            <div className="flex items-center justify-center p-5 bg-slate-900 shadow-md rounded-lg bg-opacity-70">
                                <div className="text-center ">
                                    <span className="font-inter font-semibold text-xl text-white">5000+</span>
                                    <div className="font-inter text-sm text-white">User</div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center p-5 bg-slate-900 shadow-md rounded-lg bg-opacity-70">
                                <div className="text-center">
                                    <span className="font-inter font-semibold text-xl text-white">16000+</span>
                                    <div className="font-inter text-sm text-white">Total Transaksi per Bulan</div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center p-5 bg-slate-900 shadow-md rounded-lg bg-opacity-70">
                                <div className="text-center">
                                    <span className="font-inter font-semibold text-xl text-white">3 - 7 Miliar</span>
                                    <div className="font-inter text-sm text-white">Total Nilai Transaksi per Bulan</div>
                                </div>
                            </div>
                            <div className="flex items-center justify-center p-5 bg-slate-900 shadow-md rounded-lg bg-opacity-70">
                                <div className="text-center">
                                    <span className="font-inter font-semibold text-xl text-white">150+</span>
                                    <div className="font-inter text-sm text-white">Total Vendor Terdaftar</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

        <svg className="waves pt-12" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
            <defs>
                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g className="parallax">
                <use xlinkHref="#gentle-wave" x="48" y="0" fill="#5689CE" fillOpacity="0.20" />
                <use xlinkHref="#gentle-wave" x="48" y="3" fill="#5689CE" fillOpacity="0.20" />
                <use xlinkHref="#gentle-wave" x="48" y="5" fill="#5689CE" fillOpacity="0.20" />
                <use xlinkHref="#gentle-wave" x="48" y="2" fill="#5689CE" fillOpacity="0.20" />
                <use xlinkHref="#gentle-wave" x="48" y="2" fill="#5689CE" fillOpacity="0.20" />
            </g>
        </svg>
    </div>
</div>
);
};
export default Stats