import React from 'react'
import whatsapp from '../../../assets/svg/whatsapp2.svg'
import logoheader from '../../../assets/svg/logowhite.svg'
const HeaderFeature = () => {
  return (
    <>
<div className='header-feature-wraper'>
   <div className='header-feature-title-wraper'>
            <div className='header-feature-title-large-wraper'>
                <h1 className='header-feature-title-large'>Menyediakan Layanan dengan Fitur-Fitur Terbaik</h1>
            </div>
            <div className='header-feature-paragraph-wraper'>
                <img src={logoheader} alt='' className='text-center'/>
            </div>
            <div className='mt-12'>
                <button className="button-header-whatsapp-feature"><img src={whatsapp} alt='' className='image-header-icon'/>  Hubungi Kami</button>
                <button className='button-header-gabung-feature'>GABUNG DENGAN KAMI</button>
            </div>
        </div>
   </div>
    </>
  )
}

export default HeaderFeature
