import React from 'react'
import HeaderUjiCoba from '../../widget/Detail/ujicoba/HeaderUjiCoba'
import FormComponent from '../../widget/Detail/ujicoba/FormComponent'

const UjiCoba = () => {
  return (
    <>
    <HeaderUjiCoba/>
    <FormComponent/>
    </>
  )
}

export default UjiCoba
