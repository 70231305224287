import React from 'react'
import seedFile from '../../../assets/json/seed.json'
import plantFile from '../../../assets/json/plant.json'
import threeFile from '../../../assets/json/three.json'
import Lottie from 'react-lottie'
const NilaiElkopra = () => {
  const PlantFileOptions = {
    loop: true,
    autoplay: true,
    animationData: plantFile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const SeedFileOptions = {
    loop: true,
    autoplay: true,
    animationData: seedFile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  const ThreeFileOptions = {
    loop: true,
    autoplay: true,
    animationData: threeFile,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  return (
    <>
    <div className='mt-10 text-center'>
        <h1 className='font-montserrat text-2xl'>Nilai Nilai Elkopra</h1>
   </div>
   <div className="container mx-auto mt-8 mb-8">
      <div className="bg-white rounded-lg p-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="p-4">
          <div className=' text-center justify-center'>
          <Lottie options={SeedFileOptions} height={100} width={100} />
          </div>
          <h1 className='font-montserrat text-xl text-gray-900 text-center'>Value 1</h1>
          <p className='font-inter text-gray-400 text-center text-sm'>Lorem ipsum dolor sit amet consectetur. Ornare sed aliquet quis venenatis est fermentum. Platea malesuada sed facilisi dui lacus enim quisque pharetra non.</p>
        </div>
        <div className="p-4">
          <div className=' text-center justify-center'>
          <Lottie options={PlantFileOptions} height={100} width={100} />
          </div>
          <h1 className='font-montserrat text-xl text-gray-900 text-center'>Value 2</h1>
          <p className='font-inter text-gray-400 text-center text-sm'>Lorem ipsum dolor sit amet consectetur. Ornare sed aliquet quis venenatis est fermentum. Platea malesuada sed facilisi dui lacus enim quisque pharetra non.</p>
        </div>
        <div className="p-4">
          <div className='text-center justify-center'>
          <Lottie options={ThreeFileOptions} height={100} width={100} />
          </div>
          <h1 className='font-montserrat text-xl text-gray-900 text-center'>Value 3</h1>
          <p className='font-inter text-gray-400 text-center text-sm'>Lorem ipsum dolor sit amet consectetur. Ornare sed aliquet quis venenatis est fermentum. Platea malesuada sed facilisi dui lacus enim quisque pharetra non.</p>
        </div>
      </div>
      </div>
    </div>
    </>
  )
}

export default NilaiElkopra
