import React from 'react';
import koperasiSimpanPinjam from '../../../assets/svg/pricing/koperasisimpanpinjam.svg';
import koperasiKonsumen from '../../../assets/svg/pricing/koperasikonsumen.svg';
import koperasiProdusen from '../../../assets/svg/pricing/koperasiprodusen.svg';
import KoperasiJasa from '../../../assets/svg/pricing/koperasijasa.svg';
import koperasiPemasaran from '../../../assets/svg/pricing/koperasipemasaran.svg';
import checklist from '../../../assets/svg/pricing/checklist.svg';
import close from '../../../assets/svg/pricing/close.svg';
import { Link } from 'react-router-dom';

const FeaturePricing = () => {
  return (
    <>
      <div className="container mx-auto flex items-center mt-10">
        <div className="bg-blue-900 p-1 h-10"></div>
        <p className="text-left font-montserrat text-2xl font-semibold px-4">
          Temukan berbagai pilihan untuk memenuhi kebutuhan Koperasi Anda!
        </p>
      </div>
      <div className="container mx-auto mb-5 mt-10 px-4 lg:px-0">
  <div className="overflow-x-auto lg:overflow-hidden">
    <div className="flex lg:grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-6 gap-4 whitespace-nowrap lg:whitespace-normal min-w-[250px] lg:min-w-0">
      
      {/* Feature Column */}
      <div className="p-4 bg-white rounded-lg flex flex-col justify-between min-w-[250px] lg:min-w-0">
        <h1 className="font-montserrat text-3xl text-gray-900 text-center mt-10">
          Feature
        </h1>
        <div className="space-y-16  text-center mt-[-200px]">
          <p className="font-inter text-sm text-gray-900">POS Application</p>
          <p className="font-inter text-sm text-gray-900">s/d 100 Registered members</p>
          <p className="font-inter text-sm text-gray-900">3rd Payment</p>
          <p className="font-inter text-sm text-gray-900">QRIS</p>
          <p className="font-inter text-sm text-gray-900">Reporting System</p>
          <p className="font-inter text-sm text-gray-900">Requested Fitur</p>
        </div>
      </div>

      {[
        { title: "Koperasi Simpan Pinjam", imageSrc: koperasiSimpanPinjam, features: [true, true, false, true, false] },
        { title: "Koperasi Konsumen", imageSrc: koperasiKonsumen, features: [true, true, false, true, false] },
        { title: "Koperasi Produsen", imageSrc: koperasiProdusen, features: [true, true, false, true, true] },
        { title: "Koperasi Jasa", imageSrc: KoperasiJasa, features: [true, true, true, false, true] },
        { title: "Koperasi Pemasaran", imageSrc: koperasiPemasaran, features: [true, true, true, true, false] },
      ].map((koperasi, index) => (
        <div key={index} className="p-4 bg-white rounded-lg flex flex-col justify-between min-w-[250px] lg:min-w-0">
          <div className="text-center">
            <img
              src={koperasi.imageSrc}
              alt={koperasi.title}
              className="h-24 w-24 mx-auto"
            />
          </div>
          <h1 className="font-montserrat text-base text-[#004AAD] text-center mt-10">
            {koperasi.title}
          </h1>
          <div className="space-y-12 mt-10 text-center">
            {koperasi.features.map((available, i) => (
              <img
                key={i}
                src={available ? checklist : close}
                alt={available ? "Available" : "Not Available"}
                className="mx-auto"
              />
            ))}
          </div>
          <div className="text-center mt-10">
          <Link to={'/featurepricing'}>
            <button className="bg-blue-900 text-white font-base py-3 px-4 rounded-md mb-5 font-inter border-none mt-10 cursor-pointer">
              UJI COBA GRATIS
            </button>
          </Link>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>



    </>
  );
};

export default FeaturePricing;
