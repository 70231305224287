import React from 'react'
import News from '../components/News'

const index = () => {
  return (
    <>
    <News/>
    </>
  )
}

export default index
