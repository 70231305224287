import React from 'react'
import Features from '../components/Features'

const index = () => {
  return (
    <>
    <Features/>
    </>
  )
}

export default index
