import React from 'react'
import FeaturePricing from '../../components/Detail/FeaturePricing'

const index = () => {
  return (
    <>
    <FeaturePricing/>
    </>
  )
}

export default index
