import React from 'react'
import HeaderDetailNews from '../../widget/Detail/detailnews/HeaderDetailNews'

const DetailNews = () => {
  return (
    <>
    <HeaderDetailNews/>
    </>
  )
}

export default DetailNews
