import React from 'react'
import DetailNews from '../../components/Detail/DetailNews'

const index = () => {
  return (
    <>
     <DetailNews/>
    </>
  )
}

export default index
