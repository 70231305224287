import React from 'react';
import Accordion from './Accordions';
import whatsapp from '../../../assets/svg/waborder.svg';
import { Link } from 'react-router-dom';

const FAQ = () => {
  const whatsappNumber = '6282218591556';
  const items = [
    {
      title: "Apa saja fitur utama dari aplikasi elKopra?",
      content: (
        <div>
          1.  Fitur Pengelolaan Pinjaman<br />
          2.  Fitur Pengelolaan Simpanan Pokok dan Simpanan Wajib<br />
          3.  Fitur Pelaporan Keuangan Koperasi<br />
          4.  Fitur Dashboard & Pelaporan<br />
          5.  Fitur Pengelolaan Transaksi Penjualan (Point of Sales)<br />
          6.  Fitur Point Transaksi<br /><br />
          Tak sampai di situ, untuk saat ini kami sedang mengembangkan beberapa fitur lain, seperti:<br />
          1. Multi Channel Payment<br />
          2. Payment Point Online Bank (PPOB)<br />
          3. Bisnis Non Anggota<br />
          4. Urun Dana (Crowd Funding)
        </div>
      )
    },
    {
      title: "Bagaimana bentuk laporan keuangan koperasi di elKopra?",
      content: (
        <div>
          Bentuk laporan keuangan koperasi di elKopra sudah sesuai dengan <b>Peraturan Menteri Koperasi dan UKM (Permenkop) Nomor 2 Tahun 2024 tentang Kebijakan Akuntansi Koperasi.</b>
        </div>
      )
    },
    {
      title: "Apakah elKopra sudah teruji sistem keamanan datanya?",
      content: (
      <div>
          Elkopra sudah bersertifikat ISO 27001 dan OWASP
          <br></br><br></br>
          1. ISO 27001: Sertifikasi ISO 27001 dapat membantu elkopra dalam mengelola keamanan informasi secara efektif. Dengan menerapkan standar ini, elkopra dapat memastikan bahwa proses manajemen keamanan informasi mereka sesuai dengan praktik terbaik global, yang penting untuk melindungi data sensitif dan membangun kepercayaan dengan pengguna.
          <br></br>
          2. OWASP: Mengacu pada panduan OWASP, elkopra dapat mengidentifikasi dan mengatasi kerentanan keamanan pada aplikasi web mereka. Dengan memahami dan menerapkan OWASP Top 10, elkopra dapat mengurangi risiko serangan terhadap situs web mereka, seperti serangan injeksi SQL atau kerentanan Cross-Site Scripting (XSS).
          <br></br><br></br>
          Kedua aspek ini, ISO 27001 dan panduan OWASP, dapat membantu elkopra membangun dan menjaga keamanan serta keandalan situs web mereka dalam menghadapi tantangan keamanan informasi yang semakin kompleks.
      </div>)
    },
    // {
    //   title: "Bagaimana cara mendaftar aplikasi koperasi?",
    //   content: "Lorem ipsum dolor sit amet consectetur. Pellentesque augue tristique a auctor vel integer. Integer morbi ultricies ipsum ac risus nulla mi. Scelerisque aliquam eget id blandit. Velit vulputate urna tristique consectetur metus. Tortor sed sed vestibulum varius. Sollicitudin et neque faucibus massa tristique. Molestie turpis tellus adipiscing non sit vitae arcu adipiscing vestibulum. Adipiscing tortor urna interdum semper. Amet sit feugiat risus pharetra velit viverra gravida erat. At tincidunt mi tristique facilisis consectetur magna. Tellus amet at sem consequat enim sed pretium."
    // },
    // {
    //   title: "Berapa harga berlangganan elKopra untuk koperasi saya?",
    //   content: "Lorem ipsum dolor sit amet consectetur. Pellentesque augue tristique a auctor vel integer. Integer morbi ultricies ipsum ac risus nulla mi. Scelerisque aliquam eget id blandit. Velit vulputate urna tristique consectetur metus. Tortor sed sed vestibulum varius. Sollicitudin et neque faucibus massa tristique. Molestie turpis tellus adipiscing non sit vitae arcu adipiscing vestibulum. Adipiscing tortor urna interdum semper. Amet sit feugiat risus pharetra velit viverra gravida erat. At tincidunt mi tristique facilisis consectetur magna. Tellus amet at sem consequat enim sed pretium."
    // },
    {
      title: "Bagaimana cara saya mendapatkan bantuan jika saya mengalami masalah dengan aplikasi elKopra?",
      content: (
        <div>
          Jika Anda mengalami masalah dengan aplikasi, Anda dapat menghubungi customer service kami melalui:
          <br></br><br></br>
          Nomor telepon: +62 81-11111-0210
          Email: marketing@elspasial.tech

        </div>)
    }
  ];

  return (
    <>
      <div className="container mx-auto mt-8">
        <Accordion items={items} />
      </div>
      <div className='flex flex-col items-center justify-center mt-10'>
        <h1 className='font-montserrat font-semibold mb-5 text-sm'>Tidak Ada Di sini?</h1>
        <Link 
              to={`https://wa.me/${whatsappNumber}`} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
        <button className="flex items-center justify-center bg-blue-900 text-white font-semibold py-2 px-4 rounded-md mb-5 font-inter border-none cursor-pointer">
          <img src={whatsapp} alt='' className=' mr-5' />
          HUBUNGI KAMI
        </button>
        </Link>
      </div>
    </>
  );
}

export default FAQ;
