import React, { useEffect, useState } from 'react'
import home from '../../../../assets/svg/Home.svg'
import { Link, useParams } from 'react-router-dom';
import axios from 'axios';
import { env } from '../../../../config/EnvironmentConfig'
const HeaderDetailNews = () => {
  const { id } = useParams(); 
  const [article, setArticle] = useState([]);
  const [articleVisitor, setArticleVisitor] = useState([]); 

  const GetArticleDetail = async () => {
    try {
      const response = await axios.get(env.API + `/core/landing-page/news?${id}`);
      setArticle(response.data.data); 
    } catch (error) {
      console.error('Error fetching the article details:', error);
      setArticle([]); 
    }
  };

  const GetArticleDetailVisitor = async () => {
    try {
      const response = await axios.get(env.API + `/core/landing-page/news?id=${id}`);
      setArticleVisitor(response.data.data); 
    } catch (error) {
      console.error('Error fetching the article details:', error);
      setArticleVisitor([]); 
    }
  };

  useEffect(() => {
    GetArticleDetail();
    GetArticleDetailVisitor();
  }, [id]); 

  const specificArticleId = id;

const specificArticle = article.find(articless => articless.id === specificArticleId);
  return (
    <>
    <div className=' p-12'></div>
     <div className="container mx-auto px-4">
      <div className="flex flex-col md:flex-row">
        <div className="w-full md:w-2/3 pr-4">
        <nav className="text-sm flex items-center space-x-2 mb-5">
            <a href="/" className="text-gray-400 hover:underline font-inter no-underline flex items-center">
                <img
                src={home}
                alt="HOME"
                className="mr-2"
                />
                Home
            </a>
            <span>/</span>
            <a href="/news" className="text-gray-400 hover:underline font-inter no-underline flex items-center">
                News & Blog
            </a>
            <span>/</span>
            {specificArticle ? (
              <div key={specificArticle.id}>
                <span className="text-blue-600 font-inter font-semibold">{specificArticle.categories}</span>
              </div>
            ) : (
              <div className="animate-pulse">
              <div className="h-4 bg-gray-300 rounded mb-2 w-1/3" />
            </div>
            )}
          </nav>
                  <div className="article-content">
              {specificArticle ? (
              <div key={specificArticle.id}>
                <div className="article-content">
                  <h1 className="text-2xl md:text-4xl font-bold mb-2 md:mb-4 font-montserrat">
                    {specificArticle.judul}
                  </h1>
                  <p className="text-gray-600 mb-2 md:mb-4 font-inter">
                    {specificArticle.author}, {new Date(specificArticle.created_at).toLocaleString('id-ID', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      second: 'numeric',
                      hour12: true
                    })}
                  </p>
                  <img
                    src={specificArticle.thumbnail}
                    alt={specificArticle.judul}
                    className="w-full mb-4"
                  />
                   <p
                    className="text-base md:text-lg mb-4 font-inter"
                    dangerouslySetInnerHTML={{ __html: specificArticle.konten.replace(/\n/g, '<br />') }}
                  />
                </div>
              </div>
            ) : (
              <div className="animate-pulse">
              <div className="h-8 bg-gray-300 rounded mb-4 w-1/2" /> 
              <div className="h-4 bg-gray-300 rounded mb-2 w-1/3" /> 
              <div className="h-48 bg-gray-300 rounded mb-4 w-full" /> 
              <div className="h-4 bg-gray-300 rounded mb-4 w-full" /> 
              <div className="h-4 bg-gray-300 rounded mb-4 w-full" /> 
            </div>
            )}
          </div>
        </div>
        <div className="w-full md:w-1/3 mt-5 md:mt-0">
          <div className="sidebar">
          <div className="container mx-auto flex items-center mt-10">
            <div className=" bg-blue-900 p-1 h-8">
            </div>
            <h2 className="text-lg md:text-xl font-semibold mb-2 md:mb-4 font-montserrat ml-4 mb-5">Pilihan</h2>
        </div>
        {article.length > 0 ? (
            article
              .filter(articless => articless.status === 'PUBLISHED')
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .slice(0,5)
              .map(articless => (
                <div
                  key={articless.id}
                  className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 items-start pb-6"
                >
                  <img
                    src={articless.thumbnail}
                    alt={articless.judul}
                    className="w-full sm:w-32 h-24 object-cover rounded"
                  />
                  <div>
                    <span className="block text-sm text-blue-600 font-semibold mb-1 font-inter">
                      {articless.categories}
                    </span>
                    <Link
                      to={`/detailnews/${articless.id}`}
                      className="text-xs font-semibold text-gray-800 hover:underline font-montserrat no-underline"
                    >
                      {articless.judul}   
                    </Link>
                  </div>
                </div>
              ))
          ) : (
            <div className="animate-pulse">
              {[...Array(3)].map((_, index) => (
                <div key={index} className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4 items-start border-b pb-6 mb-4">
                  <div className="h-24 w-full sm:w-32 bg-gray-300 rounded" />
                  <div className="flex-1">
                    <div className="h-4 bg-gray-300 rounded mb-2 w-1/2" />
                    <div className="h-6 bg-gray-300 rounded mb-2" /> 
                  </div>
                </div>
              ))}
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default HeaderDetailNews
