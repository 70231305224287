import React from 'react';
import imagecard2 from '../../../assets/svg/widgetclosed.svg';
import whatsapp from '../../../assets/svg/waborder.svg'
import { Link } from 'react-router-dom';

const ContactPers = () => {
  const whatsappNumber = '6282218591556';
  return (
    <div className="container mx-auto px-4 sm:px-2 lg:px-2">
      <div className="pt-20">
        <div className="bg-white rounded-lg flex flex-col lg:flex-row p-20">
          <div className='lg:w-1/2 p-5'>
            <p className='font-montserrat font-semibold text-2xl pr-40 '>Solusi terbaik, membangun Koperasi Digital Bersama ElKopra</p>
            <Link 
              to={`https://wa.me/${whatsappNumber}`} 
              target="_blank" 
              rel="noopener noreferrer"
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
            <button className="flex items-center justify-center bg-blue-900 text-white font-semibold py-2 px-4 rounded-md mb-5 font-inter border-none cursor-pointer">
              <img src={whatsapp} alt='' className=' mr-5' />
              HUBUNGI KAMI
            </button>
            </Link>
          </div>
          <div className='lg:w-1/2'>
            <img src={imagecard2} alt='' className='w-full h-52 rounded-t-lg lg:rounded-l-lg lg:rounded-t-none' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactPers;
