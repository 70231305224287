export default [
    {
        name: "",
        navbar: {
            name: 'LandingPage'
        }
    },
    {
        name: "features",
        navbar: {
            name: 'features'
        }
    },
    {
        name: "ourservices",
        navbar: {
            name: 'ourservices'
        }
    },
    {
        name: "news",
        navbar: {
            name: 'news'
        }
    },
    {
        name: "pricing",
        navbar: {
            name: 'pricing'
        }
    },
    {
        name: "featurepricing",
        navbar: {
            name: 'featurepricing'
        }
    },
    {
        name: "ujicoba",
        navbar: {
            name: 'ujicoba'
        }
    },
    {
        name: "blogpage",
        navbar: {
            name: 'blogpage'
        }
    },
    {
        name: "detailnews",
        navbar: {
            name: 'detailnews'
        }
    },
]
