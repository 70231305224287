import React from 'react'
import HeaderOurServices from '../widget/ourservices/HeaderOurServices'
import DukunganElkopra from '../widget/ourservices/DukunganElkopra'
import FAQ from '../widget/ourservices/FAQ'
import Layanan from '../widget/ourservices/Layanan'

const OurServices = () => {
  return (
  <>
  <HeaderOurServices/>
  <Layanan/>
  <DukunganElkopra/>
  <FAQ/>
  </>
  )
}

export default OurServices
