import React, { createContext, memo, useContext, useEffect, useState } from 'react'
import BlogPage from '../../pages/Detail/blogpage'
import axios from 'axios'
import { env } from '../../config/EnvironmentConfig'
const controller = createContext(null)
export const useControllerState = () => {
    return useContext(controller)
}
const Controller = memo(() => {
   
    const [berita, setBerita] = useState([])
    const [loading, setLoading] = useState(true);

    const getData = async () => {
      try {
        const response = await axios.get(env.API + "/core/landing-page/news");
        if (response.status === 200) {
            setTimeout(() => {
                setBerita(response.data.data);
                setLoading(false); 
              }, 1000);
        } else {
          console.error(`Error: ${response.status}`);
        }
      } catch (error) {
        console.error('Error:', error.message);
      }
    };
    useEffect(() => {
        getData();
      }, []);

  return  (
   <controller.Provider
   value={
    {
     berita,
     loading
    }
   }
   >
    <BlogPage/>
   </controller.Provider>
  )
})

export default Controller