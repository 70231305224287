import React from 'react'
import HeaderFeature from '../widget/features/HeaderFeature'
import NilaiElkopra from '../widget/features/NilaiElkopra'
import FiturTerbaik from '../widget/features/FiturTerbaik'

const Features = () => {
  return (
  <>
  <HeaderFeature/>
  <NilaiElkopra/>
  <FiturTerbaik/>
  </>
  )
}

export default Features
