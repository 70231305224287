import React, { useState } from 'react'
const FiturTerbaik = () => {

    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (tab) => {
      setActiveTab(tab);
    };


  return (
    <>
     <div className='mt-10 text-center'>
        <h1 className='font-montserrat text-2xl'>Fitur Terbaik Elkopra</h1>
    </div>
    <div className='container mx-auto'>
        <div className="tabs-container-wraper">
            <div 
                className={`tab ${activeTab === 1 ? 'active' : ''}`}
                onClick={() => handleTabClick(1)}
            >
            <h5 className='font-montserrat text-lg text-gray-900'>Dashboard</h5>
        </div>
        <div
            className={`tab ${activeTab === 2 ? 'active' : ''}`}
            onClick={() => handleTabClick(2)}
        >
            <h5 className='font-montserrat text-lg text-gray-900 ml-5'>Operasional Pinjaman & Simpanan</h5>
        </div>
        <div
            className={`tab ${activeTab === 3 ? 'active' : ''}`}
            onClick={() => handleTabClick(3)}
        >
            <h5 className='font-montserrat text-lg text-gray-900 ml-5'>Manajemen Barang dan Vendor</h5>
        </div>
        <div
            className={`tab ${activeTab === 4 ? 'active' : ''}`}
            onClick={() => handleTabClick(4)}
        >
            <h5 className='font-montserrat text-lg text-gray-900 ml-5'>Laporan Transaksi Koperasi</h5>
        </div>
        <div
            className={`tab ${activeTab === 5 ? 'active' : ''}`}
            onClick={() => handleTabClick(5)}
        >
            <h5 className='font-montserrat text-lg text-gray-900 ml-5'>PPOB</h5>
        </div>
    </div>
    {activeTab === 1 && (   
    <>
    <div className='mt-5 mb-5'>
        <div className=' bg-white rounded-lg'>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <div className="mb-4">
                <div className=" ml-7 mt-12">
                    <h2 className="text-lg font-semibold mb-2 font-montserrat">Pemantauan Dalam Satu Layar</h2>
                    <p className=' font-inter text-base text-gray-400'>Lorem ipsum dolor sit amet consectetur. Ornare sed aliquet quis venenatis est fermentum. 
                    Platea malesuada sed facilisi dui lacus enim quisque pharetra non.</p>
                </div>
            </div>
            <div className="mb-4">
                <div className="bg-white shadow-md p-8 rounded-lg mt-12">
                    <h2 className=" text-sm font-semibold mb-2 font-inter">Sebaran Sisa Budget Bulan Berjalan</h2>
                    <p className=' text-xs font-inter text-gray-400'>November 2021</p>
                </div>
            </div>
            </div>
        </div>
    </div>
    </>
    )}
     {activeTab === 2 && (   
    <>
        dua
    </>
    )}
     {activeTab === 3 && (   
    <>
        tiga
    </>
    )}
    {activeTab === 4 && (   
    <>
        empat
    </>
    )}
    {activeTab === 5 && (   
    <>
        lima
    </>
    )}
</div>
    </>
  )
}

export default FiturTerbaik
