import React, { useState } from 'react';
import logonavbar from '../assets/svg/logonavbar.svg'
import { Link } from 'react-router-dom';
import logofooter from '../assets/logo/logofooter.png'
import instagram from '../assets/svg/Instagram.svg'
import linkedin from '../assets/svg/LinkedIn.svg'
import facebook from '../assets/svg/Facebook.svg'
import arrowup from '../assets/svg/arrowup.svg';
import arrowdown from '../assets/svg/arrowdown.svg';
const Navbar = () => {
    const [nav, setNav] = useState(false);
    const [activeItem, setActiveItem] = useState(""); 
    const [dropdownOpen, setDropdownOpen] = useState(false);

    const handleNav = () => {
      setNav(!nav);
    };

    const closeDropdown = () => {
      setDropdownOpen(false);
    };
  
    const navItems = [
      // { id: 1, text: 'Features', path:'/features' },
      { id: 2, text: 'Our Services',path:'/ourservices' },
      { id: 3, text: 'News & Blog', path:'/news' },
      { id: 4, text: 'Pricing',path:'/pricing' },
    ];

    const dropdownItems = [
      { id: 5, text: 'Bisnis', category: 'Bisnis' },
      { id: 6, text: 'Ekonomi', category: 'Ekonomi' },
      { id: 7, text: 'Hukum', category: 'Hukum' },
      { id: 8, text: 'Infografis', category: 'Infografis' },
      { id: 9, text: 'Inspirasi Bisnis', category: 'Inspirasi Bisnis' },
      { id: 10, text: 'Internasional', category: 'Internasional' },
    ];
    const toAPP = 'https://elkopra.elkopra.com';
    return (
      <nav className="bg-white py-3 mt-[-10px] fixed w-full z-10">
  <div className="container mx-auto flex justify-between items-center">
    <div className="justify-center items-center pl-4 md:pl-0 ">
      <Link href="/" className="text-black text-xl font-bold cursor-pointer">
        <img src={logonavbar} alt='' className='w-28 cursor-pointer mt-3' />
      </Link>
    </div>
    <div className="md:hidden flex justify-end w-full cursor-pointer mr-5" onClick={handleNav}>
      {nav ? 
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
        </svg>
        : <svg className="h-8 w-8 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" d="M4 6h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2z"/>
        </svg>
      }
    </div>
    <div className="hidden md:flex flex-grow justify-center">
      <div className="flex space-x-4 pl-20">
        {navItems.map((item) => (
          <div key={item.id} className="relative flex items-center">
            <Link
              to={item.path}
              onClick={() => {
                setActiveItem(item.id);
                closeDropdown();
              }}
              className={`p-2 m-2 cursor-pointer duration-300 font-montserrat text-sm ${
                activeItem === item.id ? 'font-bold text-blue-900' : 'text-blue-900'
              } hover:text-black`}
              style={{ textDecoration: 'none' }}
            >
              {item.text}
            </Link>

            {item.id === 3 && (
              <>
                <button
                  onClick={() => setDropdownOpen(!dropdownOpen)}
                  className="flex items-center bg-transparent border-none focus:outline-none"
                >
                  <img
                    src={dropdownOpen ? arrowup : arrowdown}
                    alt="Dropdown Icon"
                    className={`w-4 h-4 text-blue-900 hover:text-black transition-colors duration-300 cursor-pointer`}
                  />
                </button>
                <div
                  className={`absolute left-0 top-full bg-white shadow-lg rounded-lg py-2 transition-all duration-500 transform origin-top ${
                    dropdownOpen ? 'block scale-y-100 opacity-100' : 'hidden scale-y-0 opacity-0'
                  }`}
                  style={{ zIndex: 50 }}
                >
                  <ul className="list-none p-0 m-0">
                    {dropdownItems.map((dropdownItem) => (
                      <li
                        key={dropdownItem.id}
                        onClick={() => {
                          setActiveItem(dropdownItem.id);
                          setDropdownOpen(false);
                          // onCategoryChange(dropdownItem.category);
                        }}
                        className={`px-4 py-3 cursor-pointer hover:text-black font-montserrat text-blue-900 transition-colors duration-300 ${
                          activeItem === dropdownItem.id ? 'font-bold' : ''
                        }`}
                        style={{ fontSize: '14px' }}
                      >
                        {dropdownItem.text}
                      </li>
                    ))}
                  </ul>
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
    <div className="hidden md:flex space-x-4 mt-2">
      <Link to={toAPP}>
        <button className="bg-blue-900 text-white px-3 py-1.5 font-inter text-sm border-none rounded-lg cursor-pointer h-full">
          MASUK
        </button>
      </Link>
      <Link to={'/ujicoba'}>
        <button className="bg-transparent text-blue-900 border border-blue-900 text-sm px-3 py-1.5 font-inter rounded-lg cursor-pointer">
          UJI COBA GRATIS
        </button>
      </Link>
    </div>
  </div>

  {/* Mobile Navigation */}
  <ul
    className={
      nav
        ? 'fixed md:hidden left-0 top-0 w-[60%] h-full bg-white ease-in-out duration-500'
        : 'ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%]'
    }
  >
    <img src={logonavbar} alt='' className='w-28 cursor-pointer mt-3 ml-5' />
    <div className="flex flex-col">
      {navItems.map(item => (
        <Link
          key={item.id}
          to={item.path}
          onClick={() => {
            setActiveItem(item.id);
            setNav(false); // Menutup navbar setelah klik menu
          }}
          className={`p-2 m-2 ml-5 cursor-pointer duration-300 hover:text-black font-montserrat text-${activeItem === item.id ? 'red' : 'gray-300'} text-sm ${activeItem === item.id ? 'font-bold' : ''}`}
          style={{ textDecoration: 'none' }}
        >
          {item.text}
        </Link>
      ))}
    </div>
  </ul>
</nav>
    );
  };

  const Footer = () => {
    const instagramLink = 'https://www.instagram.com/elkopra.id/';
    const linkedinLink = 'https://www.linkedin.com/company/elkopra-app/';
    const facebookLink = 'https://www.facebook.com/profile.php?id=61556747127630';
    const whatsappNumber = '6282218591556'; 
    return (
       <>
      <div className="bg-white p-8 mt-10">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        <div className="p-4">
          <div className='mb-5'>
            <img src={logofooter} alt='' className='text-center'/>
          </div>
          <h1 className='font-montserrat font-semibold text-xl text-gray-900 mb-5'>PT. Spasial Teknologi Indonesia</h1>
          <p className='font-inter text-gray-900 text-base'>Jl. Terusan Jakarta No.76, Antapani Tengah,<br/>
           <span className="block mt-2">Bandung, Jawa Barat, 40291</span>
           </p>
        </div>
        <div className="p-4">
            <div className=''>
              <h1 className='font-montserrat text-lg text-gray-900 font-semibold mb-5'>HUBUNGI KAMI</h1>
              <a href="mailto:spatialtech.id@gmail.com" className="text-blue-600 no-underline">
                <p className='font-inter text-gray-900 text-base cursor-pointer mb-5'>spatialtech.id@gmail.com</p>
              </a>
            </div>
            <div className=''>
              <h1 className='font-montserrat text-lg text-gray-900 font-semibold mb-5'>Nomor Sales</h1>
              <a 
                href={`https://wa.me/${whatsappNumber}`} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', color: 'inherit' }} 
              >
              <p className='font-inter text-gray-900 text-base cursor-pointer'>+62 82218591556</p>
              </a>
            </div>
        </div>
        <div className="p-4">
          <h1 className='font-montserrat text-xl text-gray-900 font-semibold mb-5'>Follow us</h1>
         <div className='flex'>
              <a 
                href={instagramLink} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', color: 'inherit' }} 
              >
              <img src={instagram} alt='' className='text-center cursor-pointer'/>
           </a>
           <a 
                href={linkedinLink} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', color: 'inherit' }} 
              >
            <img src={linkedin} alt='' className='text-center cursor-pointer'/>
            </a>
            <a 
                href={facebookLink} 
                target="_blank" 
                rel="noopener noreferrer" 
                style={{ textDecoration: 'none', color: 'inherit' }} 
              >
            <img src={facebook} alt='' className='text-center cursor-pointer'/>
            </a>
         </div>
        </div>
      </div>
      </div>
       </>
    );
  };

  export{
    Navbar,
    Footer
  }