import React from 'react'
import OurServices from '../components/OurServices'

const index = () => {
  return (
    <>
    <OurServices/>
    </>
  )
}

export default index
