import React from 'react'
import Pricing from '../components/Pricing'

const index = () => {
  return (
    <>
    <Pricing/>
    </>
  )
}

export default index
