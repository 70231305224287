import React from 'react'
import pengajuanPinjaman from '../../../../assets/svg/featurepricing/pengajuanPinjaman.svg'
import saldoSPSW from '../../../../assets/svg/featurepricing/saldoSpsw.svg'
import angsuran from '../../../../assets/svg/featurepricing/angsuran.svg'
import shu from '../../../../assets/svg/featurepricing/shu.svg'
import qrcode from '../../../../assets/svg/featurepricing/qrcode.svg'
import dataKendaraan from '../../../../assets/svg/featurepricing/dataKendaraan.svg'
import ppob from '../../../../assets/svg/featurepricing/ppob.svg'
import toko from '../../../../assets/svg/featurepricing/toko.svg'
import masterData from '../../../../assets/svg/featurepricing/masterData.svg'
import onlineApproval from '../../../../assets/svg/featurepricing/onlineApproval.svg'
import pengaturanBudget from '../../../../assets/svg/featurepricing/pengaturanBudget.svg'
import pencairanDana from '../../../../assets/svg/featurepricing/pencairanDana.svg'
import laporanPenjualan from '../../../../assets/svg/featurepricing/lampiranPenjualan.svg'
import rekapTransaksi from '../../../../assets/svg/featurepricing/rekapTransaksi.svg'
import unduhRekapData from '../../../../assets/svg/featurepricing/unduhRekapData.svg'
import riwayatPembayaran from '../../../../assets/svg/featurepricing/riwayatPembayaran.svg'
import voucher from '../../../../assets/svg/featurepricing/voucher.svg'
import stockHarian from '../../../../assets/svg/featurepricing/stockHarian.svg'
import delivery from '../../../../assets/svg/featurepricing/delivery.svg'
import pengirimanProduk from '../../../../assets/svg/featurepricing/pengirimanProduk.svg'
import kasir from '../../../../assets/svg/featurepricing/kasirBukaTutup.svg'
import waveCard from '../../../../assets/svg/featurepricing/wavecard.svg'
import { Link } from 'react-router-dom'
const FeatureAplikasi = () => {
  return (
    <>
     <div className="container mx-auto flex items-center mt-10 mb-10">
        <div className=" bg-blue-900 p-1 h-10">
        </div>
        <p className="text-left font-montserrat text-2xl font-semibold px-4">Anggota</p>
    </div>
    <div className='container mx-auto mb-5'>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-1" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={pengajuanPinjaman} alt='' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Pengajuan Pinjaman</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-2" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={saldoSPSW} alt='' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Saldo SPSW</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-3" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={angsuran} alt='' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Angsuran</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-4" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={shu} alt='' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>SHU</h1>
    </div>
  </div>
</div>
<div className='container mx-auto mb-5'>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-1" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={qrcode} alt='QR Code' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>QR Code</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-2" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={dataKendaraan} alt='Data Kendaraan' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Data Kendaraan</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-3" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={ppob} alt='PPOB' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>PPOB</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-4" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={toko} alt='Toko' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Toko</h1>
    </div>
  </div>
</div>
<div className="container mx-auto flex items-center mt-10 mb-10">
        <div className=" bg-blue-900 p-1 h-10">
        </div>
        <p className="text-left font-montserrat text-2xl font-semibold px-4">Administrasi</p>
    </div>
    <div className='container mx-auto mb-5'>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-1" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={masterData} alt='Master Data' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Master Data</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-2" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={onlineApproval} alt='Online Approval' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Online Approval</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-3" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={pengaturanBudget} alt='Pengaturan Budget' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Pengaturan Budget</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-4" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={pencairanDana} alt='Pencairan Dana' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Pencairan Dana</h1>
    </div>
  </div>
</div>
<div className='container mx-auto mb-5'>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-1" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={laporanPenjualan} alt='Laporan Penjualan' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Laporan Penjualan</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-2" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={rekapTransaksi} alt='Rekap Transaksi' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Rekap Transaksi</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-3" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={unduhRekapData} alt='Unduh Rekap Data' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Unduh Rekap Data</h1>
    </div>
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="bordered-checkbox-4" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={riwayatPembayaran} alt='Riwayat Pembayaran' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Riwayat Pembayaran</h1>
    </div>
  </div>
</div>
<div className="container mx-auto flex items-center mt-10 mb-10">
        <div className=" bg-blue-900 p-1 h-10">
        </div>
        <p className="text-left font-montserrat text-2xl font-semibold px-4">Marketing dan Sales</p>
    </div>
    <div className='container mx-auto mb-5'>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="voucher-checkbox" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={voucher} alt='Voucher' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Voucher</h1>
    </div>

    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="stock-harian-checkbox" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={stockHarian} alt='Stock Harian' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Stock Harian</h1>
    </div>

    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="delivery-checkbox" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={delivery} alt='Delivery' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Delivery</h1>
    </div>

    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="pengiriman-produk-checkbox" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={pengirimanProduk} alt='Pengiriman Produk' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Pengiriman Produk</h1>
    </div>
  </div>
</div>
<div className='container mx-auto mb-5 mt-10'>
  <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
    <div className="p-4 bg-white rounded-lg">
      <div className='text-end'>
        <input 
          id="kasir-checkbox" 
          type="checkbox" 
          value="" 
          name="bordered-checkbox" 
          className="w-4 h-4 text-[#29D23A] bg-gray-100 border-gray-300 rounded focus:ring-[#29D23A] dark:focus:ring-[#29D23A] dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
        />
      </div>
      <div className='flex justify-center mt-4 mb-4'>
        <img src={kasir} alt='Kasir Buka/Tutup' className='max-w-full h-auto' />
      </div>
      <h1 className='font-inter text-base text-gray-900 text-center font-semibold'>Kasir Buka/Tutup</h1>
    </div>
  </div>
</div>
<div className='container mx-auto mb-5'>
<div className="relative p-8 bg-[#004AAD] rounded-2xl">
    <div className=' ml-5'>
        <h1 className='font-montserrat text-lg text-white font-semibold'>5 Features Terpilih</h1>
        <h1 className='font-montserrat text-sm font-thin text-white'>Uji coba gratis 2 minggu</h1>
    </div>
    <Link to={'/ujicoba'}>
        <button className="absolute top-4 right-4 bg-white text-[#004AAD] px-4 p-5 mt-3 rounded-lg border-none w-40 cursor-pointer">
            <h2 className='font-montserrat text-sm font-semibold'>LANJUTKAN</h2>
        </button>
    </Link>
</div>
</div>
    </>
  )
}

export default FeatureAplikasi
