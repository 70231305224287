import React from 'react';
import Toyota from '../../../assets/png/mitra/toyota.png';
import KaMer from '../../../assets/png/mitra/kampusmerdeka.png';
import PiBi from '../../../assets/png/mitra/pibi.png';
import BPJS from '../../../assets/png/mitra/bpjs.png';
import UPI from '../../../assets/png/mitra/upi.png';

const IconItem = ({ image }) => (
    <div className="inline-block p-10"> 
        <img src={image} alt="" className="w-28" />
    </div>
);

const JaringanMitras = () => {
    return (
        <div className="text-center pt-20">
            <h1 className="font-montserrat text-2xl mt-10">Jaringan Mitra Kami</h1>
            <div className="text-center">
                <IconItem image={Toyota} />
                <IconItem image={UPI} />
                <IconItem image={PiBi} />
                <IconItem image={KaMer} />
                <IconItem image={BPJS} />
            </div>
        </div>
    );
};

export default JaringanMitras;
