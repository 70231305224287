import React from 'react';
import Akuntansi from '../../../assets/svg/iconpacks/akuntansi.svg';
import Angsuran from '../../../assets/svg/iconpacks/angsuran.svg';
import Dashboard from '../../../assets/svg/iconpacks/dashboard.svg';
import DownloadRekap from '../../../assets/svg/iconpacks/downloadrekap.svg';
import MasterData from '../../../assets/svg/iconpacks/masterdata.svg';
import OnlineApproval from '../../../assets/svg/iconpacks/onlineapproval.svg';
import PengajuanPinjaman from '../../../assets/svg/iconpacks/pengajuanpinjaman.svg';
import PengaturanBudget from '../../../assets/svg/iconpacks/pengaturanbudget.svg';
import PosCafe from '../../../assets/svg/iconpacks/poscafe.svg';
import PPOB from '../../../assets/svg/iconpacks/ppob.svg';
import Qrcode from '../../../assets/svg/iconpacks/qrcode.svg';
import RiwayatBulanan from '../../../assets/svg/iconpacks/riwayatbulanan.svg';
import SaldoSPSW from '../../../assets/svg/iconpacks/saldospsw.svg';
import Shu from '../../../assets/svg/iconpacks/shu.svg';
import Toko from '../../../assets/svg/iconpacks/toko.svg';
import UrunDana from '../../../assets/svg/iconpacks/urundana.svg';
import Voucher from '../../../assets/svg/iconpacks/voucher.svg';

const IconSet = () => {
    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4 md:gap-8">
                <IconItem image={PengajuanPinjaman} label="Pengajuan Pinjaman" />
                <IconItem image={SaldoSPSW} label="Saldo SPSW" />
                <IconItem image={Angsuran} label="Angsuran" />
                <IconItem image={Akuntansi} label="Akuntansi" />
                <IconItem image={PPOB} label="PPOB" />
                <IconItem image={Dashboard} label="Dashboard" />
                <IconItem image={PosCafe} label="Pos Cafe" />
                <IconItem image={Toko} label="Toko" />
                <IconItem image={Voucher} label="Voucher" />
                <IconItem image={OnlineApproval} label="Online Approval" />
                <IconItem image={DownloadRekap} label="Download Rekap" />
                <IconItem image={MasterData} label="Master Data" />
                <IconItem image={RiwayatBulanan} label="Riwayat Bulanan" />
                <IconItem image={Qrcode} label="QR code" />
                <IconItem image={PengaturanBudget} label="Pengaturan Budget" />
                <IconItem image={UrunDana} label="Urun Dana" />
                <IconItem image={Shu} label="SHU" />
            </div>
        </div>
    );
};

const IconItem = ({ image, label }) => (
    <div className="relative">
        <div className="flex items-center justify-center pt-6 px-12 mx-auto">
            <div className="flex items-center justify-center p-2 bg-white shadow-md rounded-lg h-12 w-12 mx-auto">
                <img src={image} alt="" className="h-8 w-8 mx-auto" />
            </div>
        </div>
        <span className="font-inter absolute top-full left-0 right-0 text-center text-sm mt-1">{label}</span>
    </div>
);

export default IconSet;
