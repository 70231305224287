import React from 'react'
import HeaderFeaturePricing from '../../widget/Detail/featurepricing/HeaderFeaturePricing'
import FeatureAplikasi from '../../widget/Detail/featurepricing/FeatureAplikasi'


const FeaturePricing = () => {
  return (
    <>
    <HeaderFeaturePricing/>
    <FeatureAplikasi/>
    </>
  )
}

export default FeaturePricing
