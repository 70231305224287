import React, { useState } from 'react'

const FormComponent = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
          ...prevState,
          [name]: value
        }));
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      };
  return (
    <>
    {/* <div className="container mx-auto items-center mt-10">
    <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className='flex items-center'>
            <div className=" bg-blue-900 p-1 h-10">
            </div>
            <p className="text-left font-montserrat text-2xl font-semibold px-4">
                Selesaikan pengajuan uji coba gratis dengan isi formulir
            </p>
        </div>
        <div className="p-4 bg-white rounded-lg">
            <h1 className='font-inter text-base text-gray-900 text-center'>for Form Input</h1>
        </div>
    </div>
    </div> */}
   <div className="container mx-auto p-4 mt-10">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
    <div className="bg-transparent p-4 flex items-start"> {/* Mengubah items-center menjadi items-start */}
      <div className='flex items-center'>
        <div className="bg-blue-900 p-1 h-12 "></div>
        <p className="text-left font-montserrat text-2xl font-semibold px-4">
          Selesaikan pengajuan uji coba gratis dengan isi formulir
        </p>
      </div>
    </div>
    <div className="bg-white rounded-lg p-4 px-10 flex justify-center">
      <form className="max-w-lg w-full">
        <label htmlFor="name" className='font-inter text-base text-gray-900'>Nama</label>
        <input 
          type="text" 
          id="name" 
          className="block w-full p-3 text-sm text-gray-900 border border-gray-400 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" 
          placeholder="Masukan nama lengkap" 
          required 
        />
        
        <label htmlFor="email" className='font-inter text-base text-gray-900 mt-4'>Email</label>
        <input 
          type="email" 
          id="email" 
          className="block w-full p-3 text-sm text-gray-900 border border-gray-400 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" 
          placeholder="Masukan alamat email" 
          required 
        />
        
        <label htmlFor="phone" className='font-inter text-base text-gray-900 mt-4'>Nomor Telepon</label>
        <input 
          type="tel" 
          id="phone" 
          className="block w-full p-3 text-sm text-gray-900 border border-gray-400 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" 
          placeholder="Masukan nomor telepon" 
          required 
        />
        
        <label htmlFor="koperasi" className='font-inter text-base text-gray-900 mt-4'>Nama Koperasi</label>
        <input 
          type="text" 
          id="koperasi" 
          className="block w-full p-3 text-sm text-gray-900 border border-gray-400 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-white dark:border-gray-600 dark:placeholder-gray-400 dark:text-black dark:focus:ring-blue-500 dark:focus:border-blue-500" 
          placeholder="Masukan nama koperasi" 
          required 
        />
        
        <div className='bg-[#F8F8F8] p-4 mt-5 border-gray-400 rounded-lg w-full'>
        <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 text-center">
          <div className="bg-[#CFCFCF] rounded-md p-3 font-inter text-sm text-[#1E1E1E] font-semibold">Pengajuan Pinjaman</div>
          <div className="bg-[#CFCFCF] rounded-md p-3 font-inter text-sm text-[#1E1E1E] font-semibold">Saldo SPSW</div>
          <div className="bg-[#CFCFCF] rounded-md p-3 font-inter text-sm text-[#1E1E1E] font-semibold">Master Data</div>
          <div className="bg-[#CFCFCF] rounded-md p-3 font-inter text-sm text-[#1E1E1E] font-semibold">Online Approval</div>
          <div className="bg-[#CFCFCF] rounded-md p-3 font-inter text-sm text-[#1E1E1E] font-semibold">Rekap Transaksi</div>
        </div>
      </div>
        
        <div className='mt-5 text-end'>
          <button type="submit" className="bg-blue-900 text-white px-4 py-3 rounded-lg border-none font-inter cursor-pointer">AJUKAN UJICOBA</button>
        </div>
      </form>
    </div>
  </div>
</div>
    </>
  )
}

export default FormComponent
