import React from 'react'
import HeaderPricing from '../widget/pricing/HeaderPricing'
import FeaturePricing from '../widget/pricing/FeaturePricing'

const Pricing = () => {
  return (
    <>
       <HeaderPricing/>
       <FeaturePricing/>
    </>
  )
}

export default Pricing
