import React from 'react';
import { Link } from 'react-router-dom';
import imagecard1 from '../../../assets/png/imagecard1.png';
import imagecard2 from '../../../assets/png/imagecardnew2.png';
import imagecard3 from '../../../assets/png/imagecard3.png';

const NewsSect = () => {
  return (
    <div className="container mx-auto px-4 sm:px-2 lg:px-2">
      <div className="text-center pt-20">
        <h1 className="font-montserrat text-2xl mt-10">Telusuri Pusat Berita dan Informasi Ranah Koperasi</h1>
        <div className='container mx-auto mb-5 mt-5'>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 mb-8">
            <div className="bg-white rounded-lg">
              <div className='flex justify-center'>
                <img src={imagecard1} alt='' className='w-full h-52 rounded-t-lg' />
              </div>
              <h1 className='font-inter text-base text-blue-900 px-5 font-normal mt-5'>Ekonomi</h1>
              <p className='font-montserrat text-lg font-semibold px-5'>Ekonom Beberkan Perbedaan Koperasi dengan BUMN</p>
            </div>
            <div className="bg-white rounded-lg">
              <div className='flex justify-center'>
                <img src={imagecard2} alt='' className='w-full h-52 rounded-t-lg' />
              </div>
              <h1 className='font-inter text-base text-blue-900 px-5 font-normal mt-5'>Bisnis</h1>
              <p className='font-montserrat text-lg font-semibold px-5'>Kemenkop Perkuat Kolaborasi Kembangkan Koperasi dan UMKM</p>
            </div>
            <div className="bg-white rounded-lg">
              <div className='flex justify-center'>
                <img src={imagecard3} alt='' className='w-full h-52 rounded-t-lg' />
              </div>
              <h1 className='font-inter text-base text-blue-900 px-5 font-normal mt-5'>Hukum</h1>
              <p className='font-montserrat text-lg font-semibold px-5'>Muak dengan Janji Pengelola Koperasi Raung Situbondo Nasabah Ancam Pidanakan</p>
            </div>
          </div>
          <Link to="/news" style={{ textDecoration: 'none' }} className="font-inter text-base text-blue-900 px-5 font-normal mt-20 cursor-pointer ">baca lebih banyak</Link>
        </div>
      </div>
    </div>
  );

};

export default NewsSect;
